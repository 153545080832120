<script>
export default {
  props: {
    label: String,
  },
  data() {
    return {
      visible: false
    }
  }
}
</script>

<template>
  <div>
    <btn @click="visible=!visible" class="cursor-pointer transition-colors duration-500 ease-in-out bg-primary text-white px-4 py-2 font-bold">
      {{ label }}
    </btn>
    <div class="border mt-8 border shadow rounded-lg" v-show="visible">
      <slot></slot>
    </div>
  </div>
</template>

<style>
.button-send{
  @apply transition-colors duration-500 ease-in-out bg-black text-white px-4 py-2 font-bold uppercase;
  margin-left:10px !important;
}
</style>