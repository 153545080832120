<template>
  <div class="transform duration-300">
        <div class="tab__header" >
            <span :class="selected?'text-primary':''" class="tab__link block no-underline hover:text-tertiary flex justify-start items-start" @click.prevent="active = !active">
                <span class="cursor-pointer down-Arrow transform duration-300 rounded-full inline-flex items-center justify-center pr-1" :class="active?'':'-rotate-90'">
                    <i :class="caret?'visible':'invisible'" class="fas fa-caret-down" style=""></i>
                </span>                   
                <span :class="url?'cursor-pointer':''" class="leading-tight" @click="gotoUrl(url)">{{title}}</span>
                
            </span>
        </div>
        <div class="">
            <collapse-transition>
            <div class="tab__content pl-5" v-show="active">
                <div class="">
                    <slot />
                </div>
            </div>          
            </collapse-transition>          
        </div>
    </div>
</template>

<script>
  import { CollapseTransition } from "@ivanv/vue-collapse-transition"

export default {
    props: {
        title: String,
        url:String,
        selected: Boolean,
        open: Boolean,
        caret: Boolean,
    },
    components: {
      CollapseTransition,
    },
    data(){
        return {
            active: false,
        }
    },
    methods:{
        gotoUrl(url){
            if (url){
                window.location.href = url;
            }
        }
    },
    mounted(){
        if (this.open) {
            setTimeout(() => {
                this.active=true;
            }, 100)            
        }
    }
}
</script>

<style>

</style>