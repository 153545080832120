<template>
  <div :class="active?'bg-white':'bg-gray-100'" class="transform duration-300">
        <div class="tab__header p-4" >
            <a href="#" class="tab__link py-2 block no-underline text-lg leading-tight md:text-2xl lg:text-3xl text-black italic hover:text-tertiary flex justify-between" @click.prevent="active = !active">
                <strong>{{title}}</strong>
                <span class="down-Arrow transform duration-300 rounded-full p-2 inline-flex items-center justify-center h-10 w-10" :class="active?'-rotate-180 bg-primary text-white':'border-2 border-primary text-black bg-white'">
                    <i class="fas fa-arrow-down" style="font-size:26px"></i>
                </span>                
            </a>
        </div>
        <div class="border-b border-black">
            <collapse-transition>
            <div class="tab__content w-full px-4 pb-8" v-show="active">
                <div class="w-full">
                  <slot></slot>
                </div>
            </div>          
            </collapse-transition>          
        </div>
    </div>
</template>

<script>
  import { CollapseTransition } from "@ivanv/vue-collapse-transition"

export default {
    props: {
        title: String,
    },
    components: {
      CollapseTransition,
    },
    data(){
        return {
            active: false,
        }
    }
}
</script>

<style>

</style>