<template>
  <div style="width:245px">
    <a :href="url">
      <div class="slanted-box" :class="type=='red'?'red':'grey'" >
        <div class="rect-img">
          <div class="count flex items-end justify-center" v-if="hasCount && count>0">
            <div class="font-bold">{{ count }}</div>
          </div>
          <div @mouseover="hover=true" @mouseleave="hover=false" class="relative" style="height:165px">
            <img :src="imgHover" :alt="label" class="absolute top-0 left-0 right-0 bottom-0 object-cover" v-if="imgHover"/>
            <img :src="imgSrc" :alt="label" class="absolute top-0 left-0 right-0 bottom-0 object-cover" v-show="!hover && imgHover"/>

          </div>
        </div>
        <div class="rect-text">
          <div class="text">{{ label }}</div>
        </div>
      </div>
    </a>

  </div>
</template>

<style lang="scss">
.slanted-box{

  &.red{
    .rect-img, .rect-text{
      background-color: #fa4d09;
      .text{
        color: white;
      }
    }
  }

  &.grey{
    .rect-img, .rect-text{
      background-color: #F5F4F4;
      .text{
        color: black;
      }
    }
  }

  .rect-img{
    padding: 10px 10px 0 10px;
    position: relative;

    img{
      width:100%;
      height:165px;
      object-fit: cover;
    }

    .count{
      position:absolute;
      bottom:10px;
      left:13px;
      height:43px;
      width: 50px;
      background-image:url('/assets/img/redfolder.png');
      background-position: center center;
      background-repeat: no-repeat;
      font-size:16px;
      z-index:99999;
      div{
        margin-bottom:7px;
      }
    }
  }

  .rect-text{
    clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0% 100%);
    padding: 10px;
    .text{
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      text-transform: uppercase;
      font-style: italic;
      font-weight: 700;
      margin-right:10px;
      line-height: 1;
    }
  }


}
</style>

<script>
import Slick from 'vue-slick';

export default {
  props: {
    type: String,
    imgSrc: String,
    imgHover: String,
    count: Number,
    label: String,
    url: String,
  },
  components: { Slick },
  data(){
    return {
      hover: false,

    }
  },
  computed:{
    hasCount(){
      return typeof this.count !== 'undefined';
    }
  },
  methods:{

  },
  mounted(){
    if (this.images){

    }
  }
}
</script>