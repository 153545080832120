<template>
    <div>

    <div class="slanted-btn-outer">
        <div class="slanted-btn">            
        </div>
    </div>

    <br /><br />

    <div class="outside">
        <div class="inside"></div>
    </div>

   

    </div>
    
</template>

<script>
export default {

}
</script>

<style lang="scss">

.outside {
  position: relative;
	width: 190px;
    height:60px;
	background: #fa4d09;
	-webkit-clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0% 100%);    
    clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0% 100%);    
}

.inside {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: white;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0% 100%);    
  clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0% 100%);    
  
}

.slanted-btn-outer {
  filter: drop-shadow(1px 0px 0px #fa4d09)
          drop-shadow(-1px 0px 0px #fa4d09)
          drop-shadow(0px 1px 0px #fa4d09)
          drop-shadow(0px -1px 0px #fa4d09)
          drop-shadow(1px 1px 0px #fa4d09)
          drop-shadow(-1px -1px 0px #fa4d09)
          drop-shadow(-1px 1px 0px #fa4d09)
          drop-shadow(1px -1px 0px #fa4d09);
}

.slanted-btn{
    width: 190px;
    height:60px;
    background-color: white;
    position: relative;
    height:60px;
    clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0% 100%);    
}

</style>