<template>
  <div>
      <div id="map" ref="Map">
               
      
    </div>
  </div>
</template>

<script>
export default {
    data() {
    return {      
        map: null,
        marker: null,
        options: {
            mapId:'bc68cf8fbcd702e3',
            //disableDefaultUI: true,
            center: {
              lat: 48.26118124994261, 
              lng: -79.02051706455555
            },
            zoom: 17,
        }
    }
  },
  mounted(){
    this.map = new google.maps.Map(document.getElementById('map'), this.options);

    this.marker = new google.maps.Marker({
      position: this.options.center,
      map: this.map,
      icon: "/assets/img/map-marker.png",
    });

  },
  methods: {    
     
  }
}
</script>

<style>

</style>