<template>
  <div>
    <div>
      <button class="adria-tab" :class="tab==='info' ? 'adria-tab-selected' : ''" @click="tab='info'">
        {{ info_label }}
      </button>
      <button class="adria-tab" :class="tab==='form' ? 'adria-tab-selected' : ''" @click="tab='form'">
        {{ form_label}}
      </button>
    </div>
    <div>
      <div v-if="tab==='info'">
        <slot name="info"></slot>
      </div>
      <div v-if="tab==='form'">
        <slot name="form"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 'info'
    }
  },
  props: {
    info_label: {
      type: String,
      default: 'Info'
    },
    form_label: {
      type: String,
      default: 'Form'
    }
  }
}
</script>

<style lang="scss">
.adria-tab {
  background-color: #f1f1f1;
  border: none;
  color: #333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}
.adria-tab-selected {
  background-color: #FA4D0B;
  color: #fff;
}
</style>