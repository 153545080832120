<template>    
    <div class="sbtn">        
        <button @click="open()" class="transition-colors duration-500 ease-in-out text-primary bg-white hover:bg-primary hover:text-white"><slot></slot></button>
    </div>
</template>

<script>
export default {
    props:{
      href: String,
      target: String,
    },
    methods: {
        open(){            
            window.open(this.href,this.target?this.target:"_self")            
        }
    }
}
</script>

<style lang="scss">
.sbtn{
  @apply bg-primary;
  width:fit-content;
  height:fit-content;
  clip-path:polygon(0px 0px, 0px 0px, 100% 0px, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0px 100%, 0px 16px);
}

.sbtn > button{  
  outline:none;  
  box-shadow:none;
  border:none;
  font-size:18px;
  font-style:italic;
  padding: 15px 40px;
  overflow:hidden;
  clip-path:polygon(3px 3px, 19px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 16px), calc(100% - 16px) calc(100% - 3px), 3px calc(100% - 3px), 3px 16px);  
}
</style>