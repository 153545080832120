<template>
    <div>


              <!-- template -->
            <div class="ms-lightbox-template">
                        <!-- masterslider -->
                        <div class="master-slider ms-skin-light-2 " id="masterslider">
                            
                            <div class="ms-slide" v-for="(slide, index) in images" :key="index">
                                <img src="/assets/masterslider/masterslider/style/blank.gif" :data-src="slide.src" :alt="slide.alt"/>
                                <img class="ms-thumb" :src="slide.thumb" alt="thumb" />
                                <a :href="slide.src" class="ms-lightbox" rel="prettyPhoto[gallery1]" :title="slide.alt"> lightbox </a>
                            </div>
                        
                        </div>
                        
            </div>
            <!-- end of template -->


       
    </div> 
</template>

<script>


export default {
    props: {
        images: Array,                  
    },
    components: {
       
    },
    methods:{
        
    },
    data(){
        return{

        }
    },
    mounted(){
        var slider = new MasterSlider();
 
        slider.control('arrows');
        slider.control('lightbox');
        slider.control('thumblist' , {autohide:false ,dir:'h',align:'bottom', width:130, height:85, margin:5, space:5 , hideUnder:400});
    
        slider.setup('masterslider' , {
            width:750,
            height:500,
            space:5,
            loop:true,
            view:'fade'
        });
    
        
        $("a[rel^='prettyPhoto']").prettyPhoto({
            markup: '<div class="pp_pic_holder"> \
						<div class="ppt">&nbsp;</div> \
						<div class="pp_top"> \
							<div class="pp_left"></div> \
							<div class="pp_middle"></div> \
							<div class="pp_right"></div> \
						</div> \
						<div class="pp_content_container"> \
							<div class="pp_left"> \
							<div class="pp_right"> \
								<div class="pp_content"> \
									<div class="pp_loaderIcon"></div> \
									<div class="pp_fade"> \
										<a href="#" class="pp_expand" title="Expand the image">Expand</a> \
										<div class="pp_hoverContainer"> \
											<a class="pp_next" href="#">next</a> \
											<a class="pp_previous" href="#">previous</a> \
										</div> \
										<div id="pp_full_res"></div> \
										<div class="pp_details"> \
											<div class="pp_nav"> \
												<a href="#" class="pp_arrow_previous">Previous</a> \
												<p class="currentTextHolder">0/0</p> \
												<a href="#" class="pp_arrow_next">Next</a> \
											</div> \
											<p class="pp_description"></p> \
											<a class="pp_close" href="#">Close</a> \
										</div> \
									</div> \
								</div> \
							</div> \
							</div> \
						</div> \
						<div class="pp_bottom"> \
							<div class="pp_left"></div> \
							<div class="pp_middle"></div> \
							<div class="pp_right"></div> \
						</div> \
					</div> \
					<div class="pp_overlay"></div>',
			gallery_markup: '<div class="pp_gallery"> \
								<a href="#" class="pp_arrow_previous">Previous</a> \
								<div> \
									<ul> \
										{gallery} \
									</ul> \
								</div> \
								<a href="#" class="pp_arrow_next">Next</a> \
							</div>',
        });
        
    }
}
</script>

<style lang="scss">

.pp_social {
display: none !important;
}

</style>