<template>    

        <a :href="href" download="download" class="transition-colors duration-500 ease-in-out bg-primary text-white px-4 py-2 font-bold">{{label}}</a>

</template>

<script>
export default {
    props:{
      label:String,
      href: String,
    },
}
</script>
