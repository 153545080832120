<template>
  
    <!-- MasterSlider -->
        <div id="P_masterslider" class="master-slider-parent ms-parent-id-32" >
            <!-- MasterSlider Main -->
            <div id="masterslider" class="master-slider ms-skin-metro ms-bullets-left" >
                <div class="ms-slide" data-delay="5" v-for="slide in slides" :key="slide.id">
                   
                    <img class="ms-layer w-2/5" v-if="slide.type=='banNormale'"
                         src="/assets/masterslider/showcase/images/blank.gif"
                         :data-src="slide.image"
                         alt=""
                         style=""
                         data-ease="easeOutQuint"
                         data-parallax="30"
                         data-type="image"
                         data-offset-x="0"
                         data-offset-y="-28"
                         data-origin="ml"
                         data-position="normal" />                

                    <!-- slide video layer -->
                    <div class="ms-layer" v-if="slide.type=='banVideo'" style="width:560px; height:315px"
                        data-offset-x="0"
                        data-offset-y="-28"
                        data-origin        = "ml"
                        data-type          = "video"
                        data-effect        = "bottom(90)"
                        data-duration      = "800"
                        data-autoplay      = "true"
                        data-ease          = "easeOutQuart"
                        data-position="normal"
                    >
                        <!-- this img element displays as the video cover image. It's optional you can remove it and slider will show video player. -->                        
                        <img src="/assets/masterslider/showcase/images/blank.gif" :data-src="slide.image" alt="" v-if="slide.image" />
                        <iframe :src="'https://www.youtube.com/embed/'+slide.video+'?hd=1&wmode=opaque&controls=1&showinfo=0'" width="560" height="315"> </iframe>
                    </div>   
                
                    <div class="ms-layer text-bannertitle text-6xl text-black font-extrabold uppercase leading-none italic"
                         style=""
                         data-ease="easeOutQuint"
                         data-offset-x="600"
                         data-offset-y="-150"
                         data-origin="ml"
                         data-position="normal" v-html="slide.title"></div>                   
                    <div class="ms-layer text-bannersubtitle text-2xl text-black italic mt-2 lg:mt-10"
                         style=""
                         data-ease="easeOutQuint"
                         data-offset-x="600"
                         data-offset-y="-50"
                         data-origin="ml"
                         data-position="normal" v-html="slide.description">                        
                    </div>
                    <div class="ms-layer text-xl"
                        data-ease="easeOutQuint"                         
                        data-offset-x="600"
                        data-offset-y="75"
                        data-origin="ml"
                        data-position="normal">
                        <a :href="slide.entree ? slide.entree : slide.lien" target="_self" class="uppercase italic text-primary">
                        <span class="inline">{{ btn }} </span>
                        <img src="/assets/img/ensavoirplus.png" :alt="btn" class="inline" />
                    </a>
                    </div>
                </div>
               
            </div>
            <!-- END MasterSlider Main -->
        </div>
        <!-- END MasterSlider -->

</template>

<script>
export default {
    props: {
        slides: Array,   
        btn: String,               
    },
    components: {
       
    },
    methods:{
        
    },
    data(){
        return{

        }
    },
    mounted(){
        var masterslider = new MasterSlider();

			// slider controls
			masterslider.control('bullets'     ,{ autohide:false, overVideo:true  });
            masterslider.control('timebar', {color: '#FA4E0A'});
			// slider setup
			masterslider.setup("masterslider", {
				width           : 1300,
				height          : 620,
				minHeight       : 0,
				space           : 0,
				start           : 1,
				grabCursor      : true,
				swipe           : true,
				mouse           : true,
				keyboard        : true,
				layout          : "fullwidth",
				wheel           : false,
				autoplay        : true,
				instantStartLayers:true,
				loop            : true,
				shuffle         : false,
				preload         : 0,
				heightLimit     : true,
				autoHeight      : false,
				smoothHeight    : true,
				endPause        : false,
				overPause       : true,
				fillMode        : "fill",
				centerControls  : false,
				startOnAppear   : false,
				layersMode      : "center",
				autofillTarget  : "",
				hideLayers      : false,
				fullscreenMargin: 0,
				speed           : 20,
				dir             : "h",
				parallaxMode    : 'swipe',
				view            : "wave"
			});
    
        
       
           
        
    }
}
</script>

<style>
.ms-view{
    background:none !important;
}
.ms-bullets{
    left:25px !important;
    bottom:25px !important;
}
</style>