<template>
    <a :href="url">             
        <div class="slanted-box" :class="type=='red'?'red':'grey'" @mouseover="play()" @mouseleave="pause()">
            <div class="rect-img">
                <div class="count flex items-end justify-center" v-if="hasCount">
                    <div class="font-bold">{{ count }}</div>
                </div>
                <slick v-if="images" ref="slick" :options="slickOptions">
                    <div v-for="(img) in images" :key="img.id" class="thumbnailForMainImage">
                        <img :src="img.url" :alt="img.title" />
                    </div>
                </slick>
                <img v-else :src="imgSrc" :alt="label"/>                
            </div>
            <div class="rect-text">
                <div class="text">{{ label }}</div>
            </div>
        </div>
    </a>
</template>

<style lang="scss">
.slanted-box{

    &.red{
        .rect-img, .rect-text{
            background-color: #fa4d09;
            .text{
                color: white;
            }
        }         
    }

    &.grey{
        .rect-img, .rect-text{
            background-color: #F5F4F4;
            .text{
                color: black;                
            }
        }
    }
         
    .rect-img{
        padding: 10px 10px 0 10px;
        position: relative;

        img{ 
            width:100%;
            height:165px; 
            object-fit: cover;
        }

        .count{
            position:absolute;
            bottom:10px;
            left:13px;
            height:43px;
            width: 50px;
            background-image:url('/assets/img/redfolder.png');
            background-position: center center;
            background-repeat: no-repeat;
            font-size:16px;
            z-index:99999;
            div{ 
                margin-bottom:7px;
            }
        }
    }

    .rect-text{
        clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0% 100%);
        padding: 10px;
        .text{
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            text-transform: uppercase;
            font-style: italic;
            font-weight: 700;
            margin-right:10px;
            line-height: 1;
        }
    }


}
</style>

<script>
import Slick from 'vue-slick';

export default {
    props: {
        type: String,
        imgSrc: String,           
        count: Number,      
        label: String,
        url: String,      
        images: Array,          
    },
    components: { Slick },
    data(){
        return {                        
            slickOptions:{
                 slidesToShow: 1,
                 dots:false,
                 arrows:false,
                 autoplay: false,
                 adaptiveHeight: true,
                 fade: true,
                 speed:300,
                 infinite: true,
                 autoplaySpeed: 1000,
            }

        }
    },
    computed:{
        hasCount(){
            return typeof this.count !== 'undefined';
        }
    },    
    methods:{
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        play(){            
            this.$refs.slick.goTo(1);
            this.$refs.slick.play();
        },

        pause(){
            this.$refs.slick.goTo(0);
            this.$refs.slick.pause();
        },

        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
    },
    mounted(){
        if (this.images){
        this.$refs.slick.reSlick();
        }
    }
}
</script>