<template>
    <a :href="product.url">
        <div class="whitebox">
            
            <div class="img bg-black ">
                <img :src="product.img.url" :alt="product.img.alt" class="h-greyboximg w-full object-cover" />
            </div>
            <div class="label">{{product.title}}</div>
            
        </div>
  
    </a>
</template>

<script>
export default {
    props: {
        product: Object,                  
    }
}
</script>

<style lang="scss">
.whitebox{    
    padding-top:0.5em;   
    
    height:275px;
    width:100%;
    position:relative;


    .img{
        margin-left: 0.5em;
        margin-right: 0.5em;
        width:calc(100% - 1em);
        height:225px;
        background-color: black;
    }

    .label{
        position:absolute;
        left:10px;
        bottom:10px;
        @apply uppercase;
        @apply font-bold;
        @apply italic;
    }
}
</style>