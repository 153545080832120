import Vue from "vue";
import Components from "./components";
import "./app.scss";


import VueAgile from 'vue-agile'

Vue.use(VueAgile)

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)



Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data(){
    return{
      menuOpen: false,
    }
  },
  methods:{
    openMenu(){
      this.menuOpen=true;
    },
    closeMenu(){
      this.menuOpen=false;
    },
    toggleMenu(){
      this.menuOpen=!this.menuOpen;
    }
  }
});